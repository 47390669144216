export default ({ theme }) => ({
    color: theme.palette.tertiary.contrastText,
    backgroundColor: theme.palette.tertiary.main,

    ':hover': {
        backgroundColor: theme.palette.tertiary.light,
        boxShadow: 'none',
    },

    ':focus': {
        backgroundColor: theme.palette.tertiary.main,
    },

    ':active, &[data-clicked=true]': {
        backgroundColor: theme.palette.tertiary.light,
    },
});
