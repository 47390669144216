import { createSelector } from 'reselect';

export const getMessagesState = (state) => state.messages;

export const getCurrentLocale = createSelector(
    getMessagesState,
    (messagesState) => messagesState.locale,
);

export const getMessagesForCategory = createSelector(
    getMessagesState,
    (messages) => (category) => messages.items[category],
);
