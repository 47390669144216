export const KIOSK_LEGAL_TOPIC = 'housing';

const useKiosk = () => {
    const subdomain = window.location.hostname.split('.')[0];

    return {
        isKiosk: subdomain === process.env.REACT_APP_KIOSK_SUBDOMAIN,
    };
};

export default useKiosk;
