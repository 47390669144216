import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import MuiLink from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';

import { useMessages } from '../utils/cms';
import Colors from '../styles/colors';
import { getModelsList } from '../selectors/cms';
import PrimaryButton from '../components/buttons/primary-button';
import IconTypography from '../components/icon-typography';

const FooterContainer = styled(Container)({
    paddingTop: '70px',
    paddingBottom: '60px',
    display: 'flex',
    columnGap: '45px',
});

const FooterList = styled(List)(({ theme }) => ({
    '& .MuiListItem-root': {
        paddingLeft: 0,
        paddingRight: 0,

        '& a': {
            color: theme.palette.text.primary,
            textDecoration: 'none',
            fontSize: '1.6rem',
        },
    },
}));

const now = new Date();

const Footer = () => {
    const messages = useMessages('Global');
    const getAll = useSelector(getModelsList);
    const legalTopics = useMemo(() => getAll('legal-topics'), [getAll]);

    return (
        <Box backgroundColor={Colors.white} position="relative" zIndex={2}>
            <FooterContainer>
                <Box>
                    <Box pb={2}>
                        <img className="darkLogo" src={`${process.env.PUBLIC_URL}/images/ILH-logo-dark.svg`} width={130} height="auto" alt="Indiana Legal Help Logo" />
                    </Box>
                    <MuiLink href="https://www.inbarfoundation.org/?utm_source=inbf.org&utm_medium=301&utm_campaign=redirect" target="_blank" rel="noreferrer">
                        <img src={`${process.env.PUBLIC_URL}/images/ibf-logo.png`} height="auto" width="150" alt="Go To Indiana Bar Foundation" />
                    </MuiLink>
                </Box>
                <Box>
                    <Typography variant="h5">
                        {messages.quickLinksTitle}
                    </Typography>
                    <FooterList>
                        <ListItem>
                            <Link to="/get-help">{messages.getLegalHelp}</Link>
                        </ListItem>
                        <ListItem>
                            <Link to="/about">{messages.aboutUs}</Link>
                        </ListItem>
                        <ListItem>
                            <Link to="/info">{messages.informationAndForms}</Link>
                        </ListItem>
                        <ListItem>
                            <Link to="/events">{messages.upcomingEvents}</Link>
                        </ListItem>
                        <ListItem>
                            <Link to="/give-back">{messages.giveBack}</Link>
                        </ListItem>
                        <ListItem>
                            <a href={messages.partnerResourcesUrl}>{messages.partnerResourcesLabel}</a>
                        </ListItem>
                    </FooterList>
                </Box>
                <Box>
                    <Typography variant="h5">
                        {messages.topicsFooterTitle}
                    </Typography>
                    <FooterList>
                        {legalTopics.map((topic) => (
                            <ListItem key={topic.id}>
                                <Link to={`/legal-topics/${topic.id}`}>
                                    {topic.name}
                                </Link>
                            </ListItem>
                        ))}
                    </FooterList>
                </Box>
                <Box>
                    <Typography variant="h5">
                        {messages.partnersTitle}
                    </Typography>
                    <FooterList>
                        <ListItem>
                            <MuiLink href="https://www.indianalegalservices.org/" target="_blank" rel="noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/images/ils-logo.png`} height="auto" width="150" alt="Go To Indiana Legal Service" />
                            </MuiLink>
                        </ListItem>
                        <ListItem>
                            <MuiLink href="https://www.in.gov/courts/supreme/" target="_blank" rel="noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/images/indiana-supreme-court-logo.png`} height="auto" width="204" alt="Go To Indiana Supreme Court" />
                            </MuiLink>
                        </ListItem>
                        <ListItem>
                            <MuiLink href="https://www.in.gov/ihcda/" target="_blank" rel="noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/images/IHCDA-logo.svg`} height="auto" width="150" alt="Go To Indiana Housing and Community Development Authority" />
                            </MuiLink>
                        </ListItem>
                        <ListItem>
                            <MuiLink href="https://www.lsc.gov/" target="_blank" rel="noreferrer">
                                <img src={`${process.env.PUBLIC_URL}/images/lsc-logo.png`} height="auto" width="150" alt="Go To Legal Services Corporation" />
                            </MuiLink>
                        </ListItem>
                    </FooterList>
                </Box>
                <Box>
                    <PrimaryButton sx={{ mb: 2, fontWeight: 'bold' }} component={Link} to="/give-back">
                        {messages.donate}
                    </PrimaryButton>
                    <IconTypography icon={<Icon sx={{ fontSize: '18px' }}>mail</Icon>} variant="body2" color={Colors.navy} fontWeight="bold">
                        <MuiLink sx={{ color: Colors.navy }} underline="none" href="mailto:info@indianalegalhelp.org">
                            {messages.contactUsLink}
                        </MuiLink>
                    </IconTypography>
                    <Box mt={1} />
                    <IconTypography
                        icon={(
                            <Box width="18px" textAlign="center">
                                <img height="18" width="auto" src={`${process.env.PUBLIC_URL}/images/facebook-icon.svg`} alt="" />
                            </Box>
                        )}
                        variant="body2"
                        color={Colors.navy}
                        fontWeight="bold"
                    >
                        <MuiLink sx={{ color: Colors.navy }} underline="none" href="https://www.facebook.com/profile.php?id=100083195657566" target="_blank" rel="noreferrer">
                            {messages.facebookLink}
                        </MuiLink>
                    </IconTypography>
                </Box>
            </FooterContainer>
            <Typography textAlign="center" variant="body2" pb={3}>
                &#169; {format(now, 'yyyy')} {messages.copyrightSuffix}
            </Typography>
        </Box>
    );
};

export default Footer;
