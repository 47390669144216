import MessagesActions from '../action-types/messages';

export const locales = {
    ENGLISH: 'en',
    SPANISH: 'es',
};

const internals = {
    initial: () => ({
        locale: locales.ENGLISH,
        items: {},
    }),
};

const MessagesReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case MessagesActions.RECEIVE_LOCALE:
        return {
            ...state,
            locale: payload,
        };
    case MessagesActions.RECEIVE_MESSAGES:
        return {
            ...state,
            items: payload,
        };

    default:
        return state;
    }
};

export default MessagesReducer;
