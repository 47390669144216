import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

import { getModelsList } from '../../selectors/cms';
import { fetchMessages } from '../../actions/messages';
import { useMessages } from '../../utils/cms';
import useKiosk from '../../utils/use-kiosk';
import KioskHeader from './kiosk';
import DesktopHeader from './desktop';
import MobileHeader from './mobile';
import weglot from '../../utils/weglot';

/**
 * Header Component
 */
const Header = () => {
    const isMobile = useMediaQuery('(max-width:1092px)');
    const { isKiosk } = useKiosk();
    const dispatch = useDispatch();
    const getAll = useSelector(getModelsList);
    const legalTopics = useMemo(() => getAll('legal-topics'), [getAll]);
    const [localeValue, setLocaleValue] = useState(weglot.getCurrentLang() || 'en');
    const globalMessages = useMessages('Global');

    const handleLocaleChange = (event) => {
        setLocaleValue(event.target.value);
        weglot.switchTo(event.target.value);
        setTimeout(() => window.location.reload(false), 500);
    };

    useEffect(() => {
        dispatch(fetchMessages());
    }, []);

    if (isKiosk) {
        return (
            <KioskHeader
                messages={globalMessages}
                legalTopics={legalTopics}
                handleLocaleChange={handleLocaleChange}
                locale={localeValue}
            />
        );
    }

    if (isMobile) {
        return (
            <MobileHeader
                messages={globalMessages}
                legalTopics={legalTopics}
                handleLocaleChange={handleLocaleChange}
                locale={localeValue}
            />
        );
    }

    return (
        <DesktopHeader
            messages={globalMessages}
            legalTopics={legalTopics}
            handleLocaleChange={handleLocaleChange}
            locale={localeValue}
        />
    );
};

export default Header;
