import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';

import { locales } from '../../reducers/messages';
import {
    HeaderBar,
    NavDivider,
    Navigation,
    NavLink,
    NavSelect,
} from './components';
import MegaMenu from './mega-menu';
import PrimaryButton from '../buttons/primary-button';

const NavButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    opacity: 0.7,
    fontSize: '1.5rem',
    border: 'none',
    textDecoration: 'none',
    fontWeight: 500,
    padding: 0,
    textTransform: 'uppercase',
    '&:hover, &.open, &:focus': {
        opacity: 1,
    },
    '& .MuiIcon-root': {
        color: theme.palette.secondary.main,
    },
}));

/**
 * Header Component
 */
const DesktopHeader = ({
    locale,
    messages,
    handleLocaleChange,
}) => {
    const { pathname } = useLocation();
    const megaMenuRef = useRef(null);
    const [legalTopicsOpen, setLegalTopicsOpen] = useState(false);
    const [menuActive, setMenuActive] = useState(false);

    return (
        <HeaderBar
            sx={{
                paddingTop: 0,
            }}
            position="static"
        >
            <Container>
                <Box display="flex" alignItems="center" columnGap={4} pb={2}>
                    <Link to="/">
                        <img className="logo" src={`${process.env.PUBLIC_URL}/images/ILH-logo.svg`} width={180} height="auto" alt="Indiana Legal Help Logo" />
                        <img className="darkLogo" src={`${process.env.PUBLIC_URL}/images/ILH-logo-dark.svg`} width={180} height="auto" alt="Indiana Legal Help Logo" />
                    </Link>
                    <Box flex={1} component="nav">
                        <Box display="flex" justifyContent="end" columnGap={2} pb={2}>
                            <NavSelect
                                aria-label="language"
                                value={locale}
                                onChange={handleLocaleChange}
                                variant="standard"
                                disableUnderline
                            >
                                <MenuItem value={locales.ENGLISH}>{messages.english}</MenuItem>
                                <MenuItem value={locales.SPANISH}>{messages.spanish}</MenuItem>
                            </NavSelect>
                            <PrimaryButton
                                sx={{
                                    pt: '8.5px',
                                    pb: '8.5px',
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    textTransform: 'none',
                                }}
                                component={Link}
                                to="/give-back"
                            >
                                {messages.donate}
                            </PrimaryButton>
                        </Box>
                        <Navigation component="ul">
                            <li>
                                <NavLink to="/get-help" className={pathname === '/get-help' && 'active'}>{messages.getLegalHelp}</NavLink>
                            </li>
                            <li>
                                <NavButton
                                    ref={megaMenuRef}
                                    onMouseEnter={() => setLegalTopicsOpen(true)}
                                    onMouseLeave={() => setLegalTopicsOpen(false)}
                                    className={menuActive && 'open'}
                                    component={Link}
                                    to="/legal-topics"
                                    disableFocusRipple
                                >
                                    {messages.legalTopics} {(menuActive || legalTopicsOpen) ? <Icon>arrow_drop_up</Icon> : <Icon>arrow_drop_down</Icon>}
                                </NavButton>
                                <MegaMenu menuActive={menuActive} open={legalTopicsOpen} anchorEl={megaMenuRef?.current} onClose={() => setLegalTopicsOpen(false)} setMenuActive={setMenuActive} />
                            </li>
                            <li>
                                <NavLink to="/info" className={pathname === '/info' && 'active'}>{messages.informationAndForms}</NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/events"
                                    className={pathname === '/events' && 'active'}
                                    sx={{ whiteSpace: 'nowrap' }}
                                >
                                    {messages.events}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/about" className={pathname === '/about' && 'active'} sx={{ whiteSpace: 'nowrap' }}>{messages.aboutPage}</NavLink>
                            </li>
                            <li aria-hidden>
                                <NavDivider />
                            </li>
                            <li>
                                <NavLink to="/give-back" className={pathname === '/give-back' && 'active'} sx={{ whiteSpace: 'nowrap' }}>{messages.giveBack}</NavLink>
                            </li>
                        </Navigation>
                    </Box>
                </Box>
            </Container>
        </HeaderBar>
    );
};

DesktopHeader.propTypes = {
    messages: PropTypes.object.isRequired,
    handleLocaleChange: PropTypes.func.isRequired,
    legalTopics: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired,
};

export default DesktopHeader;
