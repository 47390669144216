import CmsTypes from '../action-types/cms';

export const statuses = {
    LOADING: 'loading',
    COMPLETE: 'complete',
};

const initial = {
    status: {},
};

// eslint-disable-next-line default-param-last
const CmsReducer = (state = initial, action) => {
    const { type, payload } = action;
    switch (type) {
    // this is also a good place for things like loading and completed
    // status while the cms is fetching
    case CmsTypes.RECEIVE_MODELS:
        return {
            ...state,
            [payload.postType]: payload.models,
            status: {
                ...state.status,
                [payload.postType]: statuses.COMPLETE,
            },
        };
    case CmsTypes.CMS_ERROR: {
        const newState = { ...state, error: payload.error };
        if (payload.postType) {
            newState.status = { ...state.status, [payload.postType]: statuses.COMPLETE };
        }
        return newState;
    }
    case CmsTypes.LOADING_MODELS: {
        return { ...state, status: { ...state.status, [payload]: statuses.LOADING } };
    }
    case CmsTypes.LOADING_MODELS_COMPLETE: {
        return { ...state, status: { ...state.status, [payload]: statuses.LOADING } };
    }
    default:
        return state;
    }
};

export default CmsReducer;
