import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';

const IconBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const IconTypography = ({
    icon,
    children,
    columnGap,
    className,
    iconPlacement,
    iconText,
    width,
    ...props
}) => (
    iconPlacement === 'right' ? (
        <IconBox sx={{ columnGap, width }} className={`iconTypography ${className}`} justifyContent="space-between">
            <Typography component="div" {...props}>
                {children}
            </Typography>
            {icon}
            {iconText && <Box component="span" sx={visuallyHidden}>{iconText}</Box>}
        </IconBox>
    ) : (
        <IconBox sx={{ columnGap }} className={`iconTypography ${className}`}>
            {icon}
            {iconText && <Box component="span" sx={visuallyHidden}>{iconText}</Box>}
            <Typography component="div" {...props}>
                {children}
            </Typography>
        </IconBox>
    )
);

IconTypography.propTypes = {
    icon: PropTypes.node,
    iconPlacement: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.string,
    ]),
    columnGap: PropTypes.number,
    className: PropTypes.string,
    iconText: PropTypes.string,
    width: PropTypes.string,
};

IconTypography.defaultProps = {
    icon: null,
    iconPlacement: 'left',
    columnGap: 1,
    className: '',
    children: null,
    iconText: '',
    width: 'auto',
};

export default IconTypography;
