import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Colors from '../../styles/colors';

export const NavLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    opacity: 0.7,
    textDecoration: 'none',
    fontWeight: 500,
    textTransform: 'uppercase',
    '&.active': {
        opacity: 1,
        borderBottom: `3px solid ${theme.palette.secondary.main}`,
    },
    '&:hover, &:focus': {
        opacity: 1,
    },
    '&:focus-visible': {
        border: `2px solid ${Colors.blue}`,
        outline: 'none',
    },
}));

export const HeaderBar = styled(AppBar)(() => ({
    boxShadow: 'none',
    paddingTop: '12px',
    zIndex: 1,
    '& .darkLogo': {
        display: 'none',
    },
    '& .logo': {
        display: 'block',
    },
    '@media print': {
        '& .darkLogo': {
            display: 'block',
        },
        '& .logo': {
            display: 'none',
        },
    },
}));

export const NavSelect = styled(Select)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: '1.5rem',
    fontWeight: 500,
    '& .MuiSvgIcon-root': {
        fill: theme.palette.primary.contrastText,
    },
    '& .MuiSelect-select.MuiSelect-standard': {
        opacity: 0.7,
        '&:hover': {
            backgroundColor: 'transparent',
            opacity: 1.0,
        },
        '&:focus': {
            opacity: 1.0,
            outline: `2px solid ${Colors.blue}`,
        },
    },
}));

export const NavDivider = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.contrastText,
    height: '36px',
    width: '1px',
    opacity: 0.7,
}));

export const Navigation = styled(Box)(() => ({
    display: 'flex',
    flex: 1,
    justifyContent: 'end',
    columnGap: '38px',
    alignItems: 'center',
    listStyle: 'none',
    marginBlockStart: 0,
    fontSize: '1.5rem',
    marginBlockEnd: 0,
    '@media print': {
        display: 'none',
    },
    '& li:before': {
        content: '""',
    },
}));
