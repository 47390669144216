import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import noop from 'lodash/noop';

import { getModelsList } from '../../selectors/cms';

const MenuWrapper = styled(Box)(() => ({
    display: 'inline-flex',
    position: 'absolute',
    top: '85px',
    alignItems: 'start',
    zIndex: 5,
}));

const MenuList = styled(List)(({ theme }) => ({
    padding: 0,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0 4px 11px -7px rgba(0,0,0,0.5)',
    minWidth: '140px',
    '& .MuiListItemButton-root': {
        padding: '10px 15px',
        fontSize: '1.4rem',
        color: theme.palette.secondary.contrastText,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.secondary.light,
        },
        '&.parentTopic': {
            textTransform: 'uppercase',
            fontSize: '1.5rem',
            fontWeight: 500,
        },
        maxWidth: '250px',
    },

    '&.legalIssuesList': {
        alignSelf: 'stretch',
    },
}));

const MegaMenu = ({
    open,
    anchorEl,
    onClose,
    menuActive,
    setMenuActive,
}) => {
    const getAll = useSelector(getModelsList);
    const legalTopics = useMemo(() => getAll('legal-topics'), [getAll]);

    const handleClose = () => {
        setMenuActive(false);
        onClose();
    };

    const handleOpen = () => setMenuActive(true);

    if (!open && !menuActive) {
        return null;
    }

    return (
        <MenuWrapper
            sx={anchorEl && {
                left: anchorEl.getBoundingClientRect().left,
                top: anchorEl.getBoundingClientRect().bottom,
            }}
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
        >
            <MenuList>
                {legalTopics.map((topic) => (
                    <ListItemButton
                        className="parentTopic"
                        component={Link}
                        key={topic.id}
                        to={`/legal-topics/${topic.id}`}
                        onClick={handleClose}
                    >
                        {topic.name}
                    </ListItemButton>
                ))}
            </MenuList>
        </MenuWrapper>
    );
};

MegaMenu.propTypes = {
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    onClose: PropTypes.func,
    menuActive: PropTypes.bool,
    setMenuActive: PropTypes.func,
};

MegaMenu.defaultProps = {
    open: false,
    anchorEl: null,
    onClose: noop,
    menuActive: false,
    setMenuActive: noop,
};

export default MegaMenu;
