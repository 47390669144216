import { createTheme } from '@mui/material/styles';
import Colors from './colors';

const baseTheme = createTheme({
    typography: {
        fontFamily: '"Roboto", sans-serif',
        h1: {
            fontSize: '4.8rem',
            fontWeight: 700,
            fontStyle: 'normal',
            color: Colors.navy,
        },
        h2: {
            fontSize: '3.2rem',
            fontWeight: 700,
            fontStyle: 'normal',
            paddingBottom: 20,
            color: Colors.navy,
        },
        h3: {
            fontSize: '2.4rem',
            fontWeight: 700,
            color: Colors.navy,
        },
        h4: {
            fontSize: '2.0rem',
            fontWeight: 700,
            color: Colors.navy,
        },
        h5: {
            fontSize: '1.8rem',
            fontWeight: 700,
            color: Colors.navy,
        },
        h6: {
            fontSize: '1.6rem',
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: '1px',
        },
        body1: {
            fontSize: '1.8rem',
            fontFamily: '"Roboto", sans-serif',
            fontWeight: 400,
        },
        body2: {
            fontFamily: '"Roboto", sans-serif',
            fontSize: '1.6rem',
        },
        button: {
            textTransform: 'none',
            fontFamily: '"Roboto", sans-serif',
        },
    },
    palette: {
        text: {
            primary: Colors.greyDark,
            secondary: Colors.greyDark,
        },
        primary: {
            main: Colors.navy,
            contrastText: Colors.white,
        },
        secondary: {
            main: Colors.gold,
            light: Colors.goldLight,
            contrastText: Colors.navy,
        },
        tertiary: {
            main: Colors.stormGrey,
            light: Colors.greyLight,
            contrastText: Colors.navy,
        },
        quaternary: {
            main: Colors.navy,
            contrastText: Colors.navy,
        },
        inverse: {
            main: Colors.white,
            contrastText: Colors.navy,
        },
        error: {
            main: Colors.error,
            contrastText: Colors.white,
        },
        success: {
            main: Colors.success,
        },
        background: {
            default: Colors.navy,
        },
    },
});
const theme = createTheme(baseTheme, {
    typography: {
        h1: {
            [baseTheme.breakpoints.down('sm')]: {
                fontSize: '4.0rem',
            },
        },
        h2: {
            [baseTheme.breakpoints.down('md')]: {
                fontSize: '2.8rem',
            },
        },
        body1: {
            [baseTheme.breakpoints.down('md')]: {
                fontSize: '1.6rem',
            },
        },
        body2: {
            color: baseTheme.palette.text.secondary,
        },
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiFormControlLabel: { // checkboxes, radio buttons
            styleOverrides: {
                label: {
                    color: baseTheme.palette.text.secondary,
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    lineHeight: 'inherit',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    textTransform: 'uppercase',
                    letterSpacing: '0.0625rem',
                    fontFamily: '"Roboto", sans-serif',
                    position: 'relative',
                    top: 0,
                    left: 0,
                    color: baseTheme.palette.primary.contrastText,
                    opacity: 0.7,
                    '&.Mui-focused': {
                        color: baseTheme.palette.primary.contrastText,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    top: -15,
                    left: -15,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                    lineHeight: '1.4rem',
                    letterSpacing: '0.1rem',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.primary.dark,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.white,
                    fontSize: '1.6rem',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: Colors.blue,
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation0: { // select menus
                    border: `2px solid ${Colors.greyLight}`,
                    borderRadius: 5,
                    paddingTop: 3,
                    paddingBottom: 3,
                },
                elevation2: { // regular menus
                    borderRadius: 5,
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
                },
                elevation24: { // dialogs
                    borderRadius: 5,
                    boxShadow: `0 0 10px 0 rgba(${Colors.blueDarkRGB}, 0.2)`,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&:hover': {
                        backgroundColor: Colors.stormGrey,
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.text.secondary,
                    lineHeight: 1.1875,
                    fontSize: '1.6rem',

                    '&:hover': {
                        color: baseTheme.palette.primary.main,
                        backgroundColor: Colors.blueHighlight,
                    },

                    '&:focus': {
                        color: baseTheme.palette.primary.main,
                        backgroundColor: Colors.blueHighlight,
                        border: `2px solid ${Colors.blue}`,
                        borderRadius: '5px',
                    },

                    '&[aria-selected=true]': {
                        color: baseTheme.palette.primary.main,
                        backgroundColor: Colors.blueHighlight,
                    },

                    '&[role=option]': { // select menu options
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 22,
                        paddingRight: 22,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'uppercase',
                    fontSize: '1.6rem',
                    lineHeight: '1.7rem',
                    padding: '16px 22px',
                    boxShadow: 'none',
                    letterSpacing: '1.5px',
                    '&.Mui-focusVisible': {
                        border: `2px solid ${Colors.blue}`,
                    },
                },
                text: {
                    fontSize: '1.3rem',
                    fontWeight: 500,
                    lineHeight: 1,
                    letterSpacing: '0.05rem',
                    padding: '16px 25px',
                    boxShadow: 'none',
                    textTransform: 'uppercase',
                },
                outlined: {
                    borderWidth: '2px',
                    '&:hover': {
                        borderWidth: '2px',
                    },
                    borderRadius: '6px',
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                primary: {
                    '&.Mui-disabled, &[aria-disabled=true]': {
                        opacity: '40%',
                        backgroundColor: baseTheme.palette.primary.main,
                        color: baseTheme.palette.primary.contrastText,
                    },
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(209,211,212,0.2)',
                    '&.MuiPaper-root:last-of-type': {
                        borderBottomLeftRadius: '20px',
                        borderBottomRightRadius: '20px',
                    },
                    '&.MuiPaper-root:first-of-type': {
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                    },
                    borderRadius: '20px',
                    '&:before': {
                        backgroundColor: 'transparent',
                    },
                    boxShadow: '3px 3px 0 0 rgba(0,0,0,0.1)',
                    '&.disabled': {
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            display: 'none',
                        },
                        '& .MuiAccordionSummary-root': {
                            cursor: 'inherit',
                        },
                    },
                    '&.Mui-expanded, &:hover': {
                        boxShadow: '0 6px 30px 0 rgba(0,0,0,0.2)',
                        backgroundColor: baseTheme.palette.primary.main,
                        color: baseTheme.palette.primary.contrastText,
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    fontWeight: 700,
                    padding: '25px 30px',
                    '&:focus, &:focus-visible': {
                        border: `2px solid ${Colors.blue}`,
                        borderRadius: '10px',
                        backgroundColor: 'transparent',
                        '&.Mui-expanded': {
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                        },
                    },
                },
                content: {
                    margin: 0,
                    '&.Mui-expanded': {
                        margin: 0,
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.blueHighlight,
                    borderBottomLeftRadius: '20px',
                    borderBottomRightRadius: '20px',
                    padding: '32px',
                    color: baseTheme.palette.text.primary,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontSize: '1.6rem',
                    color: Colors.navy,
                    height: 'auto',
                    borderRadius: '20px',
                    padding: '2px 9px',
                    backgroundColor: Colors.offWhite,
                    border: 'none',
                    '@media print': {
                        fontSize: '0.9rem',
                        paddingLeft: '0',
                        paddingRight: '0',
                    },
                },
                label: {
                    paddingRight: 0,
                },
                icon: {
                    fontSize: '1.8rem',
                    color: Colors.navy,
                    marginLeft: 0,
                    '@media print': {
                        fontSize: '1.0rem',
                        paddingLeft: '0',
                        paddingRight: '0',
                    },
                },
            },
        },
        MuiIcon: {
            defaultProps: {
                baseClassName: 'material-symbols-outlined',
            },
            styleOverrides: {
                root: {
                    fontWeight: 300,
                    fontSize: '2.4rem',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    border: 'none',
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    body1: 'div',
                    body2: 'div',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                },
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                separator: {
                    color: baseTheme.palette.secondary.main,
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: Colors.blue,
                },
            },
        },
    },
});

export default theme;
