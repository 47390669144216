import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';

export const getCms = (state) => state.cms;

export const getStatuses = createSelector(
    getCms,
    (cms) => cms.status,
);

export const getModels = createSelector(
    getCms,
    (cms) => (postType) => cms[postType] || {},
);

export const getModel = createSelector(
    getModels,
    (getModelsSelector) => (postType, id) => {
        const models = getModelsSelector(postType);
        return models[id];
    },
);

export const getModelsList = createSelector(
    getModels,
    (getModelsSelector) => (postType) => {
        const models = getModelsSelector(postType);
        if (models) {
            return orderBy(Object.values(models), ['globalOrder'], ['asc]']);
        }
        return [];
    },
);

export const getFilteredModelsList = createSelector(
    getModelsList,
    (getModelsListSelector) => (postType, filters) => {
        const models = getModelsListSelector(postType);
        return models.filter((model) => {
            let isMatch = true;
            Object.entries(filters).forEach(([key, value]) => {
                if (model[key] !== value) {
                    isMatch = false;
                }
            });
            return isMatch;
        });
    },
);
