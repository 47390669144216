export default {
    white: '#ffffff',
    offWhite: '#f6f6f6',
    whiteRGB: '255, 255, 255',
    whiteDark: '#E6E6E6',
    black: '#2F3135',
    blackRGB: '47,49,53',
    grey: '#C3C4C4',
    greyRGB: '195, 196, 196',
    greyDark: '#575C66',
    greyDarkRGB: '108, 110, 113',
    greyDarker: '#575C66',
    greyLight: '#E5E7E8',
    stormGrey: '#D1D3D4',
    regalBlue: '#1D2E4B',
    regalBlueRGB: '29, 46, 75',
    blue: '#3059FF',
    blueRGB: '77, 115, 200',
    blueHighlight: '#E9F3FB',
    blueDark: '#0C0F36',
    blueDarkRGB: '12, 15, 54',
    blueLight: '#92C5EB',
    navy: '#11274A',
    gold: '#F8AF40',
    goldLight: '#FFCB7D',
    yellowGreen: '#D3E67E',
    yellowHighlight: '#FEEFD9',
    error: '#BE2A55',
    success: '#13AE47',
};
