import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Select from '@mui/material/Select';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Icon from '@mui/material/Icon';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { locales } from '../../reducers/messages';
import {
    HeaderBar,
    NavLink,
} from './components';
import PrimaryButton from '../buttons/primary-button';
import Colors from '../../styles/colors';

const MenuDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        width: '100%',
        backgroundColor: theme.palette.primary.main,
    },
}));

const MenuListItem = styled(ListItem)(() => ({
    padding: 0,
    borderBottom: `1px solid ${Colors.greyDark}`,
    '& a': {
        padding: '20px 28px',
        flex: 1,
        width: '100%',
    },
}));

const MenuHeader = styled(Box)(() => ({
    padding: '16px 28px',
    display: 'flex',
    columnGap: '20px',
    justifyContent: 'space-between',
}));

const MenuAccordion = styled(Accordion)(({ theme }) => ({
    boxShadow: 'none',
    width: '100%',
    borderRadius: '0 !important',
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${Colors.greyDark}`,

    '&:hover, &.Mui-expanded': {
        boxShadow: 'none',
        borderRadius: 0,
    },

    '& .MuiAccordionSummary-root': {
        padding: 0,
        borderRadius: 0,
        backgroundColor: 'transparent',
        textTransform: 'uppercase',
        color: theme.palette.primary.contrastText,
        opacity: 0.7,
        minHeight: 0,

        '&:hover, &:focus': {
            opacity: 1.0,
        },

        '& .MuiIcon-root': {
            color: theme.palette.secondary.main,
        },
        '& .MuiAccordionSummary-content': {
            justifyContent: 'space-between',

            '& a': {
                padding: '20px 30px',
                color: 'inherit',
                textDecoration: 'none',
            },
            '& .menuExpandButton': {
                padding: '20px 30px',
                borderLeft: `1px solid ${Colors.greyDark}`,
            },

            '&.Mui-expanded': {
                borderBottom: `1px solid ${Colors.greyDark}`,
            },
        },
    },

    '& .MuiAccordionDetails-root': {
        borderRadius: 0,
        padding: '20px 30px',
        backgroundColor: 'transparent',
        color: theme.palette.primary.contrastText,
        fontSize: '1.4rem',

        '& .MuiList-root': {
            padding: 0,
        },

        '& .MuiListItem-root': {
            paddingTop: '8px',
            paddingBottom: '8px',
            paddingLeft: '0',
            paddingRight: '0',
        },
    },
}));

const MenuSelect = styled(Select)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    opacity: 0.7,
    fontSize: '1.5rem',
    '&:hover, &:focus': {
        opacity: 1.0,
    },

    '& .MuiNativeSelect-icon': {
        color: theme.palette.primary.contrastText,
        opacity: 0.7,
        fontSize: '1.5rem',
        '&:hover, &:focus': {
            opacity: 1.0,
        },
    },

    '& .MuiNativeSelect-select.MuiNativeSelect-standard': {
        paddingRight: '16px',
    },
}));

/**
 * Header Component
 */
const MobileHeader = ({
    locale,
    legalTopics,
    messages,
    handleLocaleChange,
}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [legalTopicsExpanded, setLegalTopicsExpanded] = useState(false);
    const handleOpenMenu = () => {
        setMenuOpen(true);
    };
    const handleCloseMenu = () => {
        setMenuOpen(false);
    };
    const handleLegalTopicsExpanded = () => {
        setLegalTopicsExpanded(!legalTopicsExpanded);
    };

    return (
        <HeaderBar>
            <Container>
                <Box display="flex" alignItems="center" columnGap={2}>
                    <Link to="/">
                        <img className="logo" src={`${process.env.PUBLIC_URL}/images/ILH-logo.svg`} width={180} height="auto" alt="Indiana Legal Help Logo" />
                    </Link>
                    <Box flex={1} textAlign="right">
                        <IconButton
                            color="inherit"
                            aria-label="open menu"
                            onClick={handleOpenMenu}
                            edge="end"
                            disableFocusRipple
                            sx={{
                                '&.Mui-focusVisible .MuiIcon-root': {
                                    border: `2px solid ${Colors.blue}`,
                                },
                            }}
                        >
                            <Icon>menu</Icon>
                        </IconButton>
                    </Box>
                </Box>
            </Container>
            <MenuDrawer open={menuOpen} sx={{ width: '100%' }} component="nav" anchor="right">
                <MenuHeader>
                    <Link to="/" onClick={handleCloseMenu}>
                        <img className="logo" src={`${process.env.PUBLIC_URL}/images/ILH-logo.svg`} width={180} height="auto" alt="Indiana Legal Help Logo" />
                    </Link>
                    <Box display="flex" columnGap={1.5}>
                        <MenuSelect
                            defaultValue={locale}
                            aria-label="language"
                            onChange={handleLocaleChange}
                            native
                            disableUnderline
                            inputProps={{
                                name: 'language',
                                id: 'language-select',
                            }}
                            variant="standard"
                            sx={{
                                '& .MuiNativeSelect-select.MuiNativeSelect-standard': {
                                    opacity: 0.7,
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        opacity: 1.0,
                                    },
                                    '&:focus': {
                                        opacity: 1.0,
                                        border: `2px solid ${Colors.blue}`,
                                    },
                                },
                            }}
                        >
                            <option value={locales.ENGLISH}>{messages.english}</option>
                            <option value={locales.SPANISH}>{messages.spanish}</option>
                        </MenuSelect>
                        <IconButton
                            color="inherit"
                            aria-label="close menu"
                            onClick={handleCloseMenu}
                            edge="end"
                            sx={{
                                '&.Mui-focusVisible .MuiIcon-root': {
                                    border: `2px solid ${Colors.blue}`,
                                    borderRadius: '5px',
                                },
                            }}
                        >
                            <Icon sx={{ color: 'primary.contrastText' }}>menu</Icon>
                        </IconButton>
                    </Box>
                </MenuHeader>
                <List>
                    <MenuListItem>
                        <NavLink to="/" onClick={handleCloseMenu}>
                            {messages.home}
                        </NavLink>
                    </MenuListItem>
                    <MenuListItem>
                        <NavLink to="/get-help" onClick={handleCloseMenu}>
                            {messages.getLegalHelp}
                        </NavLink>
                    </MenuListItem>
                    <ListItem sx={{ padding: 0 }}>
                        <MenuAccordion expanded={legalTopicsExpanded}>
                            <AccordionSummary>
                                <Link to="/legal-topics" onClick={handleCloseMenu}>
                                    {messages.legalTopics}
                                </Link>
                                <Box component="button" className="menuExpandButton" onClick={handleLegalTopicsExpanded}>
                                    {(
                                        legalTopicsExpanded ? (
                                            <Icon>arrow_drop_up</Icon>
                                        ) : (
                                            <Icon>arrow_drop_down</Icon>
                                        )
                                    )}
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    {legalTopics.map((topic) => (
                                        <ListItem key={topic.id}>
                                            <NavLink to={`/legal-topics/${topic.id}`} sx={{ textTransform: 'none' }} onClick={handleCloseMenu}>
                                                {topic.name}
                                            </NavLink>
                                        </ListItem>
                                    ))}
                                </List>
                            </AccordionDetails>
                        </MenuAccordion>
                    </ListItem>
                    <MenuListItem>
                        <NavLink to="/info" onClick={handleCloseMenu}>
                            {messages.informationAndForms}
                        </NavLink>
                    </MenuListItem>
                    <MenuListItem>
                        <NavLink to="/events" onClick={handleCloseMenu}>
                            {messages.events}
                        </NavLink>
                    </MenuListItem>
                    <MenuListItem>
                        <NavLink to="/about" onClick={handleCloseMenu}>
                            {messages.aboutPage}
                        </NavLink>
                    </MenuListItem>
                    <MenuListItem>
                        <NavLink to="/give-back" onClick={handleCloseMenu}>
                            {messages.giveBack}
                        </NavLink>
                    </MenuListItem>
                </List>
                <Box p={3.5}>
                    <PrimaryButton component={Link} to="/give-back" onClick={handleCloseMenu} sx={{ textTransform: 'none' }}>
                        {messages.donate}
                    </PrimaryButton>
                </Box>
            </MenuDrawer>
        </HeaderBar>
    );
};

MobileHeader.propTypes = {
    messages: PropTypes.object.isRequired,
    handleLocaleChange: PropTypes.func.isRequired,
    legalTopics: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired,
};

export default MobileHeader;
