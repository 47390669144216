import MessagesActions from '../action-types/messages';
import { getCurrentLocale } from '../selectors/messages';
import { fetchAll, mapPageCopy } from '../utils/cms';
import { receiveCmsError } from './cms';

// eslint-disable-next-line import/prefer-default-export
export const updateLocale = (locale) => ({
    type: MessagesActions.RECEIVE_LOCALE,
    payload: locale,
});

export const receiveMessages = (messages) => ({
    type: MessagesActions.RECEIVE_MESSAGES,
    payload: messages,
});

export const fetchMessages = () => async (dispatch, getState) => {
    try {
        const data = await fetchAll('copy', getCurrentLocale(getState()));
        dispatch(receiveMessages(mapPageCopy(data, 'name')));
    } catch (error) {
        dispatch(receiveCmsError(error));
    }
};
