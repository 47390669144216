import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { locales } from '../../reducers/messages';
import { KIOSK_LEGAL_TOPIC } from '../../utils/use-kiosk';
import {
    HeaderBar,
    NavDivider,
    Navigation,
    NavLink,
    NavSelect,
} from './components';

const BackButton = styled(Button)(() => ({
    padding: '8px',
    minWidth: 0,
    '@media print': {
        display: 'none',
    },
}));

/**
 * Header Component
 */
const KioskHeader = ({
    locale,
    legalTopics,
    messages,
    handleLocaleChange,
}) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const housingTopic = useMemo(() => legalTopics.find((topic) => topic.name.toLowerCase() === KIOSK_LEGAL_TOPIC), [legalTopics]);

    const handleBack = () => navigate(-1);

    return (
        <HeaderBar>
            <Container>
                <Box display="flex" alignItems="center" columnGap={4} component="nav">
                    {pathname && pathname !== '/' && (
                        <BackButton
                            variant="outlined"
                            color="inverse"
                            onClick={handleBack}
                            aria-label={messages.backButton}
                        >
                            <Icon sx={{ fontSize: '32px' }}>arrow_back</Icon>
                        </BackButton>
                    )}
                    <Box paddingBottom="12px">
                        <img className="logo" src={`${process.env.PUBLIC_URL}/images/ILH-logo.svg`} width={180} height="auto" alt="Indiana Legal Help Logo" />
                        <img className="darkLogo" src={`${process.env.PUBLIC_URL}/images/ILH-logo-dark.svg`} width={180} height="auto" alt="Indiana Legal Help Logo" />
                    </Box>
                    <Navigation component="ul">
                        <li>
                            <NavLink to="/" className={pathname === '/' && 'active'}>{messages.getLegalHelp}</NavLink>
                        </li>
                        { housingTopic && (
                            <li>
                                <NavLink
                                    to={`/legal-topics/${housingTopic.id}`}
                                    className={pathname === `/legal-topics/${housingTopic.id}` && 'active'}
                                >
                                    {housingTopic.name}
                                </NavLink>
                            </li>
                        )}
                        <li>
                            <NavLink to="/about" className={pathname === '/about' && 'active'}>{messages.aboutPage}</NavLink>
                        </li>
                        <li aria-hidden style={{ display: 'none' /* remove when languages are ready */ }}>
                            <NavDivider />
                        </li>
                        <li style={{ display: 'none' /* remove when languages are ready */ }}>
                            <NavSelect
                                aria-label="language"
                                value={locale}
                                onChange={handleLocaleChange}
                                variant="standard"
                                disableUnderline
                                sx={{ display: 'none' }}
                            >
                                <MenuItem value={locales.ENGLISH}>{messages.english}</MenuItem>
                                <MenuItem value={locales.SPANISH}>{messages.spanish}</MenuItem>
                            </NavSelect>
                        </li>
                    </Navigation>
                </Box>
            </Container>
        </HeaderBar>
    );
};

KioskHeader.propTypes = {
    messages: PropTypes.object.isRequired,
    handleLocaleChange: PropTypes.func.isRequired,
    legalTopics: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired,
};

export default KioskHeader;
