import React, { Suspense, lazy, useEffect } from 'react';
import {
    Routes,
    Route,
    useLocation,
} from 'react-router-dom';
import { ZendeskAPI } from 'react-zendesk';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import withRoot from '../../wiring/with-root';
import themer from '../../styles/material-theme';
import Header from '../../components/header';
import Colors from '../../styles/colors';
import Footer from '../../footer';
import useKiosk from '../../utils/use-kiosk';
import useIsMobile from '../../styles/use-is-mobile';
import MobileFooter from '../../components/mobile-footer';

const Home = lazy(() => import('../../routes/home'));
const About = lazy(() => import('../../routes/about'));
const Login = lazy(() => import('../../routes/login'));
const ForgotPassword = lazy(() => import('../../routes/login/forgot-password'));
const ResetPassword = lazy(() => import('../../routes/login/reset-password'));
const LegalTopicDetail = lazy(() => import('../../routes/legal-topic'));
const LegalIssueDetail = lazy(() => import('../../routes/legal-issue'));
const VideoDetail = lazy(() => import('../../routes/video'));
const SearchPage = lazy(() => import('../../routes/search'));
const EventDetail = lazy(() => import('../../routes/event'));
const UpcomingEvents = lazy(() => import('../../routes/events'));
const FormDetail = lazy(() => import('../../routes/form'));
const OrganizationDetail = lazy(() => import('../../routes/organization'));
const InformationSheetDetail = lazy(() => import('../../routes/information-sheet'));
const GetHelpPage = lazy(() => import('../../routes/get-help'));
const LegalTopics = lazy(() => import('../../routes/legal-topics'));
const Donate = lazy(() => import('../../routes/donate'));
const ResourcesPage = lazy(() => import('../../routes/resources'));

const Main = styled('main')(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    minHeight: '100vh',
    '@screen print': {
        backgroundColor: Colors.white,
    },
}));

const PublicLayout = () => {
    const location = useLocation();
    const { isKiosk } = useKiosk();
    const isMobile = useIsMobile();

    useEffect(() => {
        // hide the zendesk widget for non-housing pages
        ZendeskAPI('messenger:set', 'zIndex', -99);
    }, [location]);

    return (
        <Suspense fallback={<div />}>
            <Main className="weglotBoundary">
                <Header />
                { (isKiosk || isMobile) && <Box height="105px" /> }
                <Routes>
                    <Route path="/about" element={<About />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/login/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/legal-topics" exact element={<LegalTopics />} />
                    <Route path="/legal-topics/:id" exact element={<LegalTopicDetail />} />
                    <Route path="/legal-topics/:parentId/legal-issues/:id" exact element={<LegalIssueDetail />} />
                    <Route path="/legal-topics/:topicId/legal-issues/:issueId/forms/:formId" exact element={<FormDetail />} />
                    <Route path="/legal-topics/:topicId/legal-issues/:issueId/forms/:formId/resources/:id" exact element={<InformationSheetDetail />} />
                    <Route path="/legal-topics/:topicId/legal-issues/:issueId/forms/:formId/videos/:id" exact element={<VideoDetail />} />
                    <Route path="/legal-topics/:topicId/legal-issues/:issueId/videos/:id" exact element={<VideoDetail />} />
                    <Route path="/legal-topics/:topicId/legal-issues/:issueId/resources/:id" exact element={<InformationSheetDetail />} />
                    <Route path="/legal-issues/:id" exact element={<LegalIssueDetail />} />
                    <Route path="/videos/:id" exact element={<VideoDetail />} />
                    <Route path="/resources/:id" exact element={<InformationSheetDetail />} />
                    <Route path="/events" exact element={<UpcomingEvents />} />
                    <Route path="/events/:id" exact element={<EventDetail />} />
                    <Route path="/organizations/:id" exact element={<OrganizationDetail />} />
                    <Route path="/search" exact element={<SearchPage />} />
                    <Route path="/get-help" exact element={<GetHelpPage />} />
                    <Route path="/give-back" exact element={<Donate />} />
                    <Route path="/info" exact element={<ResourcesPage />} />
                    <Route path="/*" element={isKiosk ? <Home /> : <ResourcesPage />} />
                </Routes>

                {!isKiosk && (
                    isMobile ? (
                        <MobileFooter />
                    ) : (
                        <Footer />
                    )
                )}
            </Main>
            <Box width="300px" height="200px" position="fixed" bottom={0} right={0} zIndex={-1} backgroundColor={Colors.navy} />
        </Suspense>
    );
};

export default withRoot(PublicLayout, themer);
