const KeyMirror = require('keymirror');

/**
 * These Action Types are used for the retrieval of generic wordpress models.
 * This would be in place of doing similar actions across separate post types.
 */
const CmsTypes = KeyMirror({
    RECEIVE_MODELS: true,
    CMS_ERROR: true,
    LOADING_MODELS: true,
    LOADING_MODELS_COMPLETE: true,
});

export default CmsTypes;
