import { combineReducers } from 'redux';
import Auth from './auth';
import User from './user';
import Cms from './cms';
import Messages from './messages';

const Reducers = () => combineReducers({
    auth: Auth,
    user: User,
    cms: Cms,
    messages: Messages,
});

export default Reducers;
